<template>
  <div class="container">
    <div class="function-part">
      <AuthorizeUserComponent :mission-type="'joinTeam'"
                              @signupSuccess="onSignupSuccess"
                              @loginSuccess="onLoginSuccess">
      </AuthorizeUserComponent>
    </div>
  </div>
</template>

<script>

import AuthorizeUserComponent from "../components/account/AuthorizeUserComponent";
import config from "../config";
export default {
  name: "JoinTeamNew",
  components: {AuthorizeUserComponent},
  data(){
    return{

    }
  },
  mounted() {

  },
  methods:{
    onLoginSuccess(){
      if(this.$route.query.inviteKey) {
        this.jump(`${config.baseUrl}team-invitation/accept?inviteKey=${this.$route.query.inviteKey}`);
      }
      else{
        this.$router.push('/index');
      }
    },
    onSignupSuccess(){
      if(this.$route.query.inviteKey) {
        this.jump(`${config.baseUrl}team-invitation/accept?inviteKey=${this.$route.query.inviteKey}`);
      } else{
        this.$router.push('/index');
      }
    },
    jump(url){
      if(url.startsWith("http")){
        window.location.href= url;
        return;
      }
      this.$router.push(url);
    }
  }
  }

</script>

<style scoped>
.container{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.function-part{
  /*border:1px dotted #aaa;*/
}
</style>
